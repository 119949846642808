
import React from 'react';
import { 
  Col, 
  Fa,
} from 'mdbreact'

const Juliana = () => {
  return (
    [
    <Col md="3" lg="3" className="float-left">
      <img className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid" tag="img" src="/images/team/Juliana4.png" alt="Smiley face" />

    </Col>,

    <Col md="9" lg="9" className="float-right">
      <h4 className="font-weight-bold mb-3">Juliana Da Luz Castro</h4>
      <h6 className="font-weight-bold grey-text mb-3">Licenciada en Ingeniería Química - Bachiller en Ingeniería Agroindustrial</h6>
      <p>
        La Ing. Juliana Da Luz Castro es Licenciada en Ingeniería Química de la Universidad de Costa Rica y Bachiller en Ingeniería Agroindustrial de la Universidad Estatal a Distancia. 
      </p>
      <p>
        Inspectora de Inocuidad del Instituto Nacional de Aprendizaje e inspectora Nivel  de instalaciones de Gas Licuado de Petróleo. Docente en la Escuela de Ingeniería Química de la Universidad de Costa Rica. Amplia experiencia en inspección y certificación de instalaciones de Gas Licuado de Petróleo, así como tramitología de permisos ante la Dirección de Hidrocarburos.
      </p>  
      
      <a className="p-2 fa-lg fb-ic">
        <Fa icon="facebook"/>
      </a>
      <a className="p-2 fa-lg tw-ic">
        <Fa icon="twitter"/>
      </a>
 
    </Col>
    ])
}

export default Juliana
