/*
 NOTE
	Todas las paginas van dentro de este Layout
	Las paginas son incluidas en el layout usando props.children
*/

// React 
import React from 'react'

// componentes
import '../estilos/Estilos.css'
import BarraNav from './BarraNav'
import PiePagina from './PiePagina'

const Layout = (props) => {
	return (

		// Use .container-fluid for a full width container, spanning the entire width of the viewport.
		// <div className="container-fluid body-bg">
		<div className='container-fluid'>
			<BarraNav/>
			{props.children}
			<PiePagina/>
		</div>
	)
}


export default Layout