import React from 'react';
import { Col, Row} from 'mdbreact'


const TextoEmergencias= () => {
  return (
    <div>
      <h1 id='emergenciasNacionales' style={{textAlign:'center', padding:'40px'}}>
        EMERGENCIAS NACIONALES
      </h1>
      <Row style={{marginLeft:'20px', marginRight:'20px', fontSize:'20px', paddingBottom:'20px'}}>
        <Col>
          <p style={{ fontSize:'20px' }} >
            En pocos años se ha incrementado dramáticamente el número de accidentes relacionados con la utilización de cilindros de gas licuado de petróleo, generados principalmente por
          </p>
          <ul>
            <li>el mal estado de los cilindros</li>
            <li>equipos</li>
            <li>accesorios</li>
            <li>aditamentos</li>
            <li>malas prácticas de manipulación</li>
            <li>tenencia de los cilindros</li>
          </ul>

          <p>
            produciendo daños materiales, daños a la salud y la seguridad de las personas, así como lamentables pérdidas de vidas humanas. 
          </p>
          <p>
            Conforme a la información suministrada por el Benemérito Cuerpo de Bomberos de Costa Rica, un alto porcentaje de las emergencias se produce por la antigüedad y deterioro del cilindro y por el uso de válvulas de acople rápido y reguladores en mal estado, así como malas instalaciones para el uso de GLP.
          </p>
        </Col>
        <Col>
          <img src="/images/glp/EstadisticaAccidentes.png" className="img-fluid" alt="Smiley face" height="707" width="921"/>
        </Col>
      </Row>
    </div>
  )
}

export default TextoEmergencias 

