import React, { Component } from 'react';
// import ReactDOM from 'react-dom';

import TextoGasLicuado from '../texto/TextoGasLicuado'
import TextoConsumoNacional from '../texto/TextoConsumoNacional'
import TextoEmergencias from '../texto/TextoEmergencias'
import TextoNormas from '../texto/TextoNormas'
import TextoPerfil from '../texto/TextoPerfil'
import TextoContacto from '../texto/TextoContacto'
import TextoLegislacion from '../texto/TextoLegislacion';
import TextoEquipo from '../texto/TextoEquipo';
import TextoServicios from '../texto/TextoServicios';
import TextoCotizaciones from '../texto/TextoCotizaciones';

const estiloTitulo = {
  position: 'relative',
  left: '100px',
  top: '30px',
  color:'yellow'
};

const PaginaInformacion = () => {
  return (
    <div className='wrapper'>

      <section id='inicio' className="section parallax bg10" style={{top:'140px'}}>
        <div className='recuadro' />
          <h1 style={estiloTitulo}>
            Certificaciones de instalaciones de Gas GLP
          </h1>
      </section>
    
      {/* <section  className="static"> */}
        <TextoGasLicuado/>
      {/* </section> */}

      <section className="section parallax bg51">
      <div className='recuadro'/>
        <div>
          <h1 style={estiloTitulo}>
            Consumo Nacional de GLP
          </h1>

        </div>
      </section>

      <section style={{background: 'whitesmoke', }}>
        <TextoConsumoNacional/>
      </section>

      <section  className="section parallax bg52">
        <div className='recuadro'/>
          <div>
            <h1 style={estiloTitulo}>
              Emergencias Nacionales
            </h1>
        </div>
      </section>
    
      <section style={{background: 'whitesmoke'}}>
        <TextoEmergencias/>
      </section>

      <section  className="section parallax bg53">
        <div className='recuadro'/>
          <div>
            <h1 style={estiloTitulo}>
            Legislación Nacional
            </h1>
        </div>
      </section>

      <section style={{background: 'whitesmoke'}}>
        <TextoLegislacion/>
      </section>

      <section className="section parallax bg54">
        <div className='recuadro'/>
          <div>
            <h1 style={estiloTitulo}>
              Normas internacionales
            </h1>
        </div>
      </section>

      <section style={{background: 'whitesmoke'}}>
        <TextoNormas/>
      </section>

      <section  className="section parallax bg1">
        <div className='recuadro'/>
          <div>
            <h1 style={estiloTitulo}>
              Perfil de TRAQUISA
            </h1>
        </div>
      </section>

      <section  style={{background: 'whitesmoke'}}>
        <TextoPerfil/>
      </section>


      <section className="section parallax bg55">
        {/* <div className='recuadro'/> */}
          <div>
            <h1 style={estiloTitulo}>
              Nuestro equipo de trabajo
            </h1>
        </div>
      </section>

      <section style={{background: 'whitesmoke'}}>
        <TextoEquipo/>
      </section>    


      <section className="section parallax bg42">
        {/* <div className='recuadro'/> */}
          <div>
            <h1 style={estiloTitulo}>
              Servicios de GLP
            </h1>
        </div>
      </section>

      <section style={{background: 'whitesmoke'}}>
        <TextoServicios/>
      </section>   


    </div>
  )
}

export default PaginaInformacion