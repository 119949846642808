import React from 'react';
import { Col, Row} from 'mdbreact'


const TextoNormas = () => {
  return (
    <div>
      <h1 id='normasInternacionales' style={{textAlign:'center', padding:'40px'}}>
      NORMAS INTERNACIONALES APLICABLES EN LOS SISTEMAS DE GLP
      </h1>

      <Row style={{marginLeft:'20px', marginRight:'20px', fontSize:'20px'}}>
        <Col>
          <p>
            Para la instalación de sistemas de Gas LP se utilizan varias normas internacionales entre ellas: la NFPA 54 “Código de gas combustible”, la NFPA 58 “Manipulación y almacenamiento de gas licuado de Petróleo”, la NFPA 10 de extintores portátiles y la NFPA101 "código de seguridad humana".
          </p>
        </Col>
      </Row>
   
      <br/>

      <Row style={{marginLeft:'20px', marginRight:'10px', fontSize:'20px', paddingBottom:'20px'}}>
        <Col>
          <img src="/images/glp/nfpa54.png" style={anchoFoto}/>
         <p style={estilo1}>
          La NFPA 54, ANSI Z223.1 proporciona requisitos mínimos de seguridad para el diseño e instalación de sistemas de tuberías de gas combustible en hogares y otros edificios. Esta se encarga del regulador hasta el interior del edificio.
         </p>
        </Col>


        <Col>
          <img src="/images/glp/nfpa58.png" style={anchoFoto}/>
         <p style={estilo1}>
          Como referencia en la industria para el almacenamiento, manejo, transporte y uso seguros de gas LP, NFPA 58 mitiga los riesgos y garantiza instalaciones seguras, para evitar fallas, fugas y alteraciones que podrían provocar incendios y explosiones.Esta norma  cubre toda la instalación exterior hasta el regulador.
         </p>
        </Col>

      </Row>

      <Row style={{marginLeft:'20px', marginRight:'10px', fontSize:'20px', paddingBottom:'20px'}}>
        <Col>
          <img src="/images/glp/nfpa10.png" style={anchoFoto}/>
         <p style={estilo1}>
          La NFPA 10 proporciona requisitos para garantizar que los extintores portátiles funcionen según lo previsto para proporcionar una primera línea de defensa contra incendios de tamaño limitado.
         </p>
        </Col>


        <Col>
          <img src="/images/glp/nfpa101.png" style={anchoFoto}/>
          <p style={estilo1}>
            El Código de seguridad personal es la fuente más ampliamente utilizada para las estrategias para proteger a las personas según las características de construcción, protección y ocupación del edificio que minimizan los efectos del fuego y los peligros relacionados. Único en el campo, es el único documento que cubre la seguridad de la vida en estructuras nuevas y existentes.
          </p>
        </Col>



      </Row>

    </div>
  )
}

export default TextoNormas 

const estilo1 = {
  position: 'absolute',
  top: '10px',
  left: '250px',
  maxWidth: '600px'
}

const anchoFoto = {
  width: '200px'
}