import React from 'react';
import { Col, Row} from 'mdbreact'


const Textolegislacion = () => {
  return (
    <div>
      <h1 id='legislacionNacional' style={{textAlign:'center', padding:'40px'}}>
      LEGISLACION NACIONAL APLICABLE AL SISTEMA DE GAS LICUADO DE PETRÓLEO
      </h1>
      <Row style={{marginLeft:'20px', marginRight:'20px', fontSize:'20px'}}>
        <Col>
          <p>
            El pasado 17 de mayo se publica en la Gaceta con alcance N°103, el Decreto 41150-MINAE-S, “Reglamento General para la Regulación del Suministro de Gas Licuado de Petróleo”, en el que tiene los siguientes aspectos de importancia y que son de interés público:
          </p>
        </Col>
      </Row>
   
      <br/>
      <Row style={{marginLeft:'20px', marginRight:'20px', fontSize:'20px', paddingBottom:'20px'}}>
        <Col>

          <p>
            Artículo 1º - <b>Objetivo.</b> Regular la cadena de suministro del gas licuado de petróleo como mecanismo para garantizar el abastecimiento al usuario final, la protección ambiental y la seguridad de las personas. 
          </p>
    
          <p>
            Artículo 2º - <b>Alcance.</b> Este reglamento es aplicable a toda persona física o jurídica que interviene en la cadena de suministro de gas licuado de petróleo y a las actividades que éstos ejecutan para brindar el servicio público.
          </p>

          <p>
            Artículo 5º - <b>Normativa vigente.</b>  Las  personas  físicas  o  jurídicas  que  desarrollen  actividades  de  la cadena de suministro de GLP deben cumplir con Ley de la Autoridad Reguladora de los Servicios Públicos, Ley N° 7593, Ley del Benemérito Cuerpo de Bomberos de Costa Rica, Nº 8228, Reglamento a la Ley N° 8228 del Benemérito Cuerpo de Bomberos de Costa Rica, N° 37615-MP, las normas de la NFPA aplicables, disposiciones técnicas emitidas por el BCBCR, este Reglamento, los Reglamentos Técnicos Centroamericanos, normas técnicas aplicables y la legislación que garantiza la protección ambiental y la seguridad de las personas.
          </p>

          <p>
            Artículo 10º - <b>Ministerio de Salud.</b> Otorgará el PSF a los establecimientos que desarrollan actividades industriales, comerciales y de servicios. Realizará la verificación y control o la clausura de éstos por incumplimiento según los requisitos y trámites establecidos en el Reglamento General para Autorizaciones y Permisos Sanitarios de Funcionamiento Otorgados por el Ministerio de Salud, Decreto Ejecutivo N° 39472-S del 18 de enero de 2016 y sus reformas.
          </p>

        </Col>
        <Col>
          <p>
            <b>Como requisito para el otorgamiento o renovación del PSF,</b> las personas físicas o jurídicas en cuyos establecimientos <b>utilicen o expendan GLP</b>, deberán presentar un informe técnico de inspección emitido por el BCBCR o por profesional colegiado inscrito en el RRT, según lo establecido en este reglamento. El informe deberá certificar que las instalaciones, sistemas y accesorios utilizados cumplen la legislación y la normativa técnica vigente en materia de prevención, seguridad humana y protección contra incendios. Los costos del informe técnico de inspección correrán por cuenta del solicitante.  Asimismo, cualquier otra función que sea aplicable y concordante en esta materia contenida en la Ley Orgánica del Ministerio de Salud, Ley N° 5412, el Reglamento Orgánico del Ministerio de Salud, Decreto Ejecutivo Nº 40724-S del 23 de septiembre de 2017 y la Ley General de Salud, Ley Nº 5395 y sus reformas.
          </p>
          <p>
            <b>Artículo 14º - Benemérito Cuerpo de Bomberos de Costa Rica.</b> Emitirá las normas en materia de prevención, seguridad humana y protección contra incendios que deberá cumplir toda construcción nueva, edificación existente o lugares donde se desarrollen actividades industriales, comerciales y de servicios, sean estos temporales o permanentes, que utilicen o expendan GLP. Esta normativa será revisada anualmente y es de carácter obligatorio. 
          </p>
        </Col>
      </Row>
    </div>
  )
}

export default Textolegislacion 

