import React from 'react';
import { 
  Col, 
  Row,
  Card,
  CardBody,
  Fa,
  Input,
  Button,
  Avatar

} from 'mdbreact'

import Andres from './Andres'
import Eugenia from './Eugenia'
import Jose from './Jose'
import Daniel from './Daniel'
import Tamy from './Tamy'
import Juliana from './Juliana'


const TextoEquipo = () => {
  return (
    <div>
      <section>
        <h1 id='equipoTrabajo' style={{textAlign:'center', padding:'40px'}}>
          NUESTRO EQUIPO DE TRABAJO
        </h1>
        <p className="grey-text w-responsive mx-auto mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur veniam.</p>
      <Row className="text-md-left">
        <Col lg="6" md="12" className="mb-5">
          <Eugenia/>
        </Col>          
        <Col lg="6" md="12" className="mb-5">
          <Jose/>
        </Col>
        <Col lg="6" md="12" className="mb-5">
          <Daniel/>
        </Col>
        <Col lg="6" md="12" className="mb-5">
          <Andres/>
        </Col>
        <Col lg="6" md="12" className="mb-5">
          <Tamy/>
        </Col>
        <Col lg="6" md="12" className="mb-5">
          <Juliana/>
        </Col>
      </Row>
      </section>
    </div>
  )
}

export default TextoEquipo 

const estilo1 = {
  position: 'absolute',
  top: '10px',
  left: '250px',
  maxWidth: '600px'
}

const anchoFoto = {
  width: '200px'
}