import React, {Component}  from 'react';
import '../estilos/Estilos.css'

import { NavHashLink as NavLink } from 'react-router-hash-link';

import {
	Navbar, 
	NavbarBrand, 
	NavItem, 
	NavbarNav, 
	Collapse,	
	NavbarToggler,
	DropdownToggle, 
	Dropdown, 
	DropdownMenu, 

} from 'mdbreact';

const ancho = {
	paddingRight: '40px',
	color:'white'
}

class BarraNav extends Component {

	constructor  (props)  {
		super(props)
		this.state = {
			collapse: false,
			isWideEnough: false,
		}
	}

	navbarToglerHandler = () => {
		this.setState({	collapse: !this.state.collapse	})
	} 
	
	toggleInfoGlpOpen = () => {
		this.setState({dropdownInfoGlpOpen: !this.state.dropdownInfoOpen})
	}

	toggleInfoEmpresaOpen = () => {
		this.setState({dropdowInfoEmpresaOpen: !this.state.dropdownCotizacionOpen})
	}

	opcionesInfoGlp = () => {
		return ([
			<NavItem style={{paddingLeft:'20px'}}>
				<NavLink smooth style={ancho} to="/#mercadoNacional">
					Consumo de GLP
				</NavLink>
			</NavItem>,

			<NavItem style={{paddingLeft:'20px'}}>
				<NavLink smooth style={ancho} to="/#emergenciasNacionales">
					Emergencias
				</NavLink>
			</NavItem>,

			<NavItem style={{paddingLeft:'20px'}}>
				<NavLink smooth style={ancho} to="/#legislacionNacional">
					Legislación
				</NavLink>
			</NavItem>,

			<NavItem style={{paddingLeft:'20px'}}>
				<NavLink smooth style={ancho} to="/#normasInternacionales">
					Normas
				</NavLink>
			</NavItem>,
		])
	}

	opcionesInfoEmpresa = () => {
		return ([
			<NavItem style={{paddingLeft:'20px'}}>
				<NavLink smooth style={ancho}  to="/#textoPerfil">
					Perfil  
				</NavLink>
			</NavItem>,

			<NavItem style={{paddingLeft:'20px'}}>
				<NavLink smooth style={ancho}  to="/#equipoTrabajo">
					Equipo de trabajo  
				</NavLink>
			</NavItem>,

			<NavItem style={{paddingLeft:'20px'}}>
				<NavLink smooth style={ancho}  to="/#textoServicios">
					Servicios  
				</NavLink>
			</NavItem>,

		])
	}

	render () {
		return (
			<div>
				<Navbar fixed="top" color={ 'elegant-color-dark' } dark expand="lg" scrolling className='barra-nav' style={barraNav}>
	
					<NavbarBrand style={{paddingRight:'20px',fontSize:'25px'}}> 
						<img src= "images/LogoTraquisa-200x141.png" height="75" alt=''/><span className='tit-ciqpa' >Transformaciones Químicas S.A.</span>
					</NavbarBrand>
	
					{ !this.state.isWideEnough && <NavbarToggler onClick = { this.navbarToglerHandler } />}
					<Collapse isOpen = { this.state.collapse } navbar>
								
							<NavbarNav left>
	
								<NavItem>
									<NavLink smooth style={ancho} to="/#inicio">
										Inicio 
									</NavLink>
								</NavItem>

								{/* Dropdown de Informacion de GLP */}
								<NavItem>
									<Dropdown isOpen={this.state.dropdownInfoOpen} toggle={this.toggleInfoGlpOpen}>
										<DropdownToggle style={{paddingTop:'0px', paddingRight:'20px' }} nav caret>Información sobre GLP</DropdownToggle>

										
										<DropdownMenu>
											{this.opcionesInfoGlp}
											
											{/* <DropdownItem href='/#mercadoNacional'>Consumo de GLP</DropdownItem>
											<DropdownItem href='/#emergenciasNacionales'>Emergencias</DropdownItem>
											<DropdownItem href='/#legislacionNacional'>Legislación</DropdownItem>
											<DropdownItem href='/#normasInternacionales'>Normas</DropdownItem> */}

										</DropdownMenu>
									</Dropdown>
								</NavItem>

								{/* Dropdown de Informacion empresarial */}
								<NavItem>
									<Dropdown isOpen={this.state.dropdownInfoOpen} toggle={this.toggleInfoEmpresaOpen}>
										<DropdownToggle style={{paddingTop:'0px', paddingRight:'20px' }} nav caret>Información empresarial</DropdownToggle>
										<DropdownMenu>
											{this.opcionesInfoEmpresa}
										</DropdownMenu>
									</Dropdown>
								</NavItem>


								<NavItem>
									<NavLink smooth style={ancho}  to="/contactenos">
										Contáctenos  
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink smooth style={ancho}  to="/cotizaciones">
										Cotizaciones  
									</NavLink>
								</NavItem>
								
							</NavbarNav>
							
							<NavbarNav right> 
					
							</NavbarNav>
	
					</Collapse>
				</Navbar>
				
				{/* <ToastContainer hideProgressBar={true}  newestOnTop={true} autoClose={5000} /> */}
			</div>
		)
	}
}

export default BarraNav

const barraNav = {
	margin: '15px',
	marginTop: '0px'
}

// const color = 'elegant-color-dark'
// const color = 'light-green darken-3'