import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {database} from '../firebase/config' 
import axios from 'axios';

import { 
  Col, 
  Card,
  CardTitle,
  Row,
  Container,
  Input,
  // InputNumeric,
  Button,
  Modal, 
  ModalBody, 
  ModalHeader, 
  ModalFooter,
  MDBSelect,
  MDBSelectOptions,
  MDBSelectOption,
  MDBSelectInput,

} from 'mdbreact'

import Titulo from '../componentes/Titulo'
import Parrafo from '../componentes/Parrafo' 
import TipoEstablecimiento from '../componentes/TipoEstablecimiento'
import CantidadCilindros from '../componentes/CantidadCilindros'
import CantidadLozas from '../componentes/CantidadLozas'
import CantidadEquipos from '../componentes/CantidadEquipos'
import CantidadPisos from '../componentes/CantidadPisos'
import Area from '../componentes/Area'
import dataCilindros from '../funciones/dataCilindros'
import textoCorreoCotizacion from '../texto/textoCorreoCotizacion' 

class PaginaCotizaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipoEstablecimiento: '',
      cilindros: dataCilindros,

      cantidadLozas: '',
      cantidadEquipos: '',
      cantidadPisos:'',
      cotizacionManual: false,
      area: '',
      seccionCotizacion: false,
      precio: '',
      modalPrecio: false,
      nombreEmpresa: '',
      cedulaJuridica: '', 
      direccion: '',
      nombre: '',
      telefono: '',
      correo: '',
    }
  }

  limpiarDatos = () => {
    let cilindros =  this.state.cilindros.map( c => {
      return {tipo: c.tipo, masa: c.masa, cantidad: 0}
    } )

    this.setState ({
      tipoEstablecimiento: '',
      cilindros: cilindros,
      cantidadLozas:'',
      cantidadEquipos: '',
      cantidadPisos:'',
      cotizacionManual: false,
      area: '',
      seccionCotizacion: false,
      modalPrecio: false,
      precio: '',
      nombreEmpresa: '',
      cedulaJuridica: '', 
      direccion: '',
      nombre: '',
      telefono: '',
      correo: '',

    })
  }

  cerrarModalPrecio = () => {
    this.setState({
      seccionCotizacion: false,
      modalPrecio: false
    });
  }

  //  con esta funcion pienso sustituir todos los Set...bueno no todos... la mayoria
  setVariable = ({target: {value, name}}) => {
    if (value.length > 3)  return 
    
    if (isNaN(value)) {
      this.setState({[name]: value})
      return
    }

    this.setState ({[name]: value})
  }
  
  setArea = (area) => {
    if (area.length > 4)  return 
    if (isNaN(area) ) {
      this.setState({area: ''})
      return
    }
    this.setState({area: area})
  }


  setTipoEstablecimiento = (tipoEstablecimiento) => {
    this.setState({tipoEstablecimiento})
  }

  setCantidadCilindro = (tipo, cantidad) => {
    if (cantidad.length > 3)  return 

    const cilindros = this.state.cilindros
    const index = cilindros.findIndex(cilindro => cilindro.tipo === tipo)
    
    if (isNaN(cantidad) ) {
      cilindros[index].cantidad = ''
      return
    }

    cilindros[index].cantidad = cantidad
    this.setState({cilindros})

  }

  getCantidadCilindro = (tipo) => {
    const cilindro = this.state.cilindros.find(cilindro => cilindro.tipo === tipo) 
    // console.log(cilindro.cantidad)
    return cilindro.cantidad
  }

  getTotalMasa = () => {
    let total = 0
    this.state.cilindros.forEach (cilindro => total = total + cilindro.cantidad * cilindro.masa)
    return total
  }

  getCantidadLozas = () => {
    return this.state.cantidadLozas
  }

  getCantidadEquipos = () => {
    return this.state.cantidadEquipos
  }

  getArea = () => {
    return this.state.area
  }

  getPisos = () => {
    return this.state.cantidadPisos
  }

  datosInvalidos = () => {
    let cil = 0
    if (!this.state.tipoEstablecimiento) {
      toast.warning ('Seleccione un tipo de instalación')
      return true
    }

    this.state.cilindros.forEach ( cilindro => {
      if (cilindro.cantidad > 0) {
        cil = 1
      }}
    )

    if (cil === 0) {
      toast.warning ('Digite la cantidad de cilindros de su instalación')
      return true
    }

    if (this.state.cantidadLozas === '' ) {
      toast.warning ('Digite la cantidad de lozas de su instalación')
      return true
    }

    if (this.state.cantidadEquipos === '' ) {
      toast.warning ('Digite la cantidad de equipos de su instalación')
      return true
    }
    
    if (this.state.area === '' ) {
      toast.warning ('Digite el área en metros cuadrados de su instalación')
      return true
    }

    if (this.state.cantidadPisos === '' ) {
      toast.warning ('Digite la cantidad de pisos de su instalación')
      return true
    }
  }

  datosInvalidosSolicitud = () => {
    if (!this.state.nombreEmpresa) {
      toast.warning ('Digite nombre de su empresa')
      return true
    }

    if (!this.state.cedulaJuridica) {
      toast.warning ('Digite Cédula Jurídica de su empresa')
      return true
    }
    
    if (!this.state.direccion) {
      toast.warning ('Digite la direccion de su empresa')
      return true
    }
        
    if (!this.state.nombre) {
      toast.warning ('Digite su nombre')
      return true
    }
            
    if (!this.state.telefono) {
      toast.warning ('Digite su teléfono')
      return true
    }

    if (!this.state.correo) {
      toast.warning ('Digite su correo')
      return true
    }
  }

  calcularPrecio = () => {
    let qCilindros = 0
    let masaTotal = 0 
    let {
      tipoEstablecimiento, 
      cantidadEquipos, 
      cantidadLozas, 
      cantidadPisos, 
      area,
      cotizacionManual,
    } = this.state

    const qEquipos = parseInt(cantidadEquipos)
    const qLozas = parseInt(cantidadLozas)
    const qPisos = parseInt(cantidadPisos)
    const qArea = parseInt(area)
    
    this.setState({cotizacionManual: false, precio: 0})
  
    
    this.state.cilindros.forEach( cil => {
      if (cil.cantidad) {
        qCilindros = qCilindros + parseInt(cil.cantidad)
        masaTotal = masaTotal + parseInt(cil.cantidad) * cil.masa
      }
    })
    
    console.log('Tipo establecimiento:',tipoEstablecimiento)
    console.log('Cantidad de equipos:', qEquipos)
    console.log('Cantidad de lozas:', qLozas)
    console.log('Cantidad de pisos:', qPisos)
    console.log('Area:', qArea)
    console.log('Masa total:',  masaTotal)
    console.log('Cantidad de cilindros', qCilindros)

    if (tipoEstablecimiento === 'Punto de venta de cilindros' && qCilindros <= 50) {
      this.setState({precio: 150})
      console.log('regla 1')
      return
    }

    if (tipoEstablecimiento === 'Punto de venta de cilindros' && qCilindros > 50) {
      this.setState({precio: 250})
      console.log('regla 2')
      return
     }

    if (tipoEstablecimiento === 'Distribuidor de cilindros') {
      this.setState({precio: 400})
      console.log('regla 3')
      return
    } 

    if (masaTotal <= 228.8 && qLozas <=2 && qEquipos <= 5 && qPisos < 2 && area <= 200) {
      this.setState({precio: 150})
      console.log('regla 4')
      return   
    }

    if (masaTotal > 228.8 &&  masaTotal <= 504 && qLozas <=4 && qEquipos <= 10 && qPisos <= 7 && qArea <= 500) {
      this.setState({precio: 250})
      console.log('regla 5')
      return   
    }

    if (masaTotal > 504 &&  masaTotal <= 2289.2 && qLozas <=4 && qEquipos <= 10 && qPisos <= 7 && qArea > 500) {
      this.setState({precio: 400})
      console.log('regla 6')
      return   
    }

    if (masaTotal > 2289.2 &&  masaTotal <= 7630.5 && qLozas <=4 && qEquipos <= 10 && qPisos <= 7 && qArea > 500) {
      this.setState({precio: 500})
      console.log('regla 7')
      return   
    }

    if (masaTotal > 2289.2 &&  masaTotal <= 7630.5 && qLozas <=4 && qEquipos <= 20 && qPisos > 7 && qArea > 500) {
      this.setState({precio: 500})
      console.log('regla 8')
      return   
    }

    if (masaTotal > 7630.5) {
      this.setState({cotizacionManual: true})
      console.log('regla 9')
      return
    }

    console.log('No aplico ninguna regla de cotización')
    this.setState({cotizacionManual: true})
  }

  enviarSolicitud = async (data) => {
    if ( this.datosInvalidosSolicitud() ) return

    const solicitudRef = database.collection('solicitudes').doc()
    try {
      await solicitudRef.set({
        nombreEmpresa: data.nombreEmpresa,
        nombre: data.nombre,
        telefono: data.telefono,
        correo: data.correo,
        direccion: data.direccion,
        cedulaJuridica: data.cedulaJuridica,
        cantidadPisos: data.cantidadPisos,
        cantidadLozas: data.cantidadLozas,
        cantidadEquipos: data.cantidadEquipos,
        area: data.area,
        cilindros: data.cilindros,
      })
      
      // console.log('PASSW:', process.env.REACT_APP_PASSW)

      const html = textoCorreoCotizacion (data)
      
      await axios.post
      ('https://traquisa-mailer.herokuapp.com/api/enviarCorreo', {
        
        to: data.correo,
        from: 'servicioalcliente@traquisa.net',
        subject: 'Solicitud de cotización de servicios de certificacion de uso de GLP',
        // text: 'Su solicitud fue recibida por nuestro centro de atención al cliente',
        html: html,
        passw: process.env.REACT_APP_PASSW,
      })

      toast.success('Su solicitud fue enviada a Traquisa, pronto le estaremos contactando. Muchas gracias')
      this.cerrarModalPrecio()

    }
    catch(error) {console.log('error en grabacion de solicitud:', error)} 
  } //fin enviarSolicitud

  render () {
    return (
      <Card >

        <Titulo/>
        <Parrafo/>

        <TipoEstablecimiento 
          setTipoEstablecimiento={this.setTipoEstablecimiento}     
          tipoEstablecimiento={this.state.tipoEstablecimiento}
        />

        {/* {this.state.tipoEstablecimiento != 'Punto de venta de cilindros' && this.state.tipoEstablecimiento != 'Distribuidor de cilindros' && */}
        [
          <CantidadCilindros 
            cilindros={this.state.cilindros}
            setCantidadCilindro={this.setCantidadCilindro} 
            getCantidadCilindro={this.getCantidadCilindro}
          />,

          <CantidadLozas 
            // setCantidadLozas={this.setCantidadLozas} 
            setCantidadLozas={this.setVariable}
            getCantidadLozas={this.getCantidadLozas}
          />,

          <CantidadEquipos
            // setCantidadEquipos={this.setCantidadEquipos} 
            setCantidadEquipos={this.setVariable} 
            getCantidadEquipos={this.getCantidadEquipos}
          />,

          <Area
            setArea={this.setArea} 
            getArea={this.getArea}
          />,

          <CantidadPisos
            setPisos={this.setVariable} 
            getPisos={this.getPisos}
          />,

        {/* ]} */}

        <section style={{marginLeft:'40px'}}>
          <Row>
            <Col>

              <Button 
                onClick={() => {
                  if (this.datosInvalidos()) return
                  this.calcularPrecio()
                  this.setState({ modalPrecio: true})
                }}
                color="default">Calcule el precio aproximado
              </Button>      
              
              <Button color="warning"
                onClick={() => {
                  this.limpiarDatos()
                }}
              >
                Digitar datos de nuevo
              </Button>
              

            </Col>
          </Row>
        </section>

        {/* {this.state.precioToggle &&
        <Precio precio={this.state.precio}/>
        } */}

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
          lightBackground
          // position={ToastContainer.POSITION.BOTTOM_CENTER}
          position='top-center'
          />

      {/* Modal de calculo de precio */}
       <Modal isOpen={this.state.modalPrecio} toggle={this.toggleModalPrecio} size='lg'>
          <ModalHeader style={{position:'relative', left:'6%'}} >
            <h3>Precio aproximado de una <b>Certificación de uso de GLP</b></h3> 
          </ModalHeader>
          <ModalBody>
            {!this.state.cotizacionManual &&
              <div>
                <p>
                  Con base en los datos aportados, una certificación de uso de gas GLP, confeccionada por nuestra oficina podría tener un costo aproximado de
                </p>
                <p style={{fontSize:'40px', left:'300px', position:'relative'}}>
                  ${this.state.precio}
                </p>
                <p>
                  <i>
                    <b>Nota: </b>
                    Por favor tome en cuenta que si su establecimiento se encuentra fuera del Gran área metropolitana (GAM), el servicio tendrá un costo extra de $20 adicionales por hora de traslado del profesional, tanto de ida como de vuelta.</i>
                </p>                
              </div>}

            {this.state.cotizacionManual &&
            <p>
              Por favor contactar directamente a la oficina de Traquisa para hacer un cá lculo exacto de la cotización.
            </p>}
         
            <Input 
              label="Enviarme una cotización" 
              type="checkbox" 
              id="ckCotizacion"  
              onClick= {(e) => {
                this.setState({seccionCotizacion: e.target.checked})
              }}
            />

            {this.state.seccionCotizacion && 
              <section>
                <br/>

                <p><b>Proporciónenos sus datos y le haremos llegar una cotización formal del servicio.</b></p>


                <Input
                  style={{ maxWidth: '80%'}}
                  size="sm"
                  onChange={
                    (e) => this.setState({nombreEmpresa: e.target.value})
                  }
                  value={
                    this.state.nombreEmpresa
                  }
                  label='Nombre de la empresa'
                />

                <Input
                  style={{ maxWidth: '30%'}}
                  size="sm"
                  onChange={
                    (e) => this.setState({cedulaJuridica: e.target.value})
                  }
                  value={
                    this.state.cedulaJuridica
                  }
                  label='Cédula jurídica'
                />

                <Input
                  style={{ maxWidth: '80%'}}
                  // type="textarea"
                  size="sm"
                  onChange={
                    (e) => this.setState({direccion: e.target.value})
                  }
                  value={
                    this.state.direccion
                  }
                  label='Dirección de la empresa'
                />

                <Input
                  style={{ maxWidth: '80%'}}
                  size="sm"
                  onChange={
                    (e) => this.setState({nombre: e.target.value})
                  }
                  value={
                    this.state.nombre
                  }
                  label='Su nombre'
                />

                <Input
                  style={{ maxWidth: '30%'}}
                  size="sm"
                  onChange={
                    (e) => this.setState({telefono: e.target.value})
                  }
                  value={
                    this.state.telefono
                  }
                  label='Teléfono'
                />

                <Input
                  style={{ maxWidth: '30%'}}
                  size="sm"
                  onChange={
                    (e) => this.setState({correo: e.target.value})
                  }
                  value={
                    this.state.correo
                  }
                  label='Correo electrónico'
                />
                
                {/* Este campo es por si algun robot agazapado se pone tonto */}
                <Input style = {{display:'none'}} type="checkbox" id="robot"  
                  onClick= {this.cerrarModalPrecio} />

              </section>
            }

          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={this.cerrarModalPrecio}>
              Cerrar
            </Button>
            {/* <Button color="default" onClick={() => this.props.history.push(`${PAGINA_CURSO}/${this.props.match.params.codigoCurso}`)}>Solicitar cotización</Button> */}
            <Button disabled = {!this.state.seccionCotizacion} color='default' onClick={() => this.enviarSolicitud(this.state)}>
               Enviar solicitud
            </Button>

          </ModalFooter>
        </Modal>
      </Card>
    )
  }
}

export default PaginaCotizaciones