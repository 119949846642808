
import React from 'react';
import { 
  Col, 
   
  Fa,
  

} from 'mdbreact'
const Daniel = () => {
  return ([
    <Col md="3" lg="3" className="float-left">
      <img className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid" tag="img" src="/images/team/Daniel.png" alt="Smiley face" />
    </Col>,
    <Col md="9" lg="9" className="float-right">
      <h4 className="font-weight-bold mb-3">Daniel Ulloa</h4>
      <h6 className="font-weight-bold grey-text mb-3">Estudiante de Ingeniería Química UCR</h6>
      <p>
        Daniel Ulloa Ovares es estudiante egresado de la carrera de Ingeniería Química de la Universidad de Costa Rica. 
      </p>
      <p>
        Areas de desarrollo: ingeniera ambiental, específicamente el tratamiento de residuos, solidos, líquidos y gaseosos de manera integral. 
      </p>

      <a className="p-2 fa-lg fb-ic">
        <Fa icon="facebook"/>
      </a>
      <a className="p-2 fa-lg tw-ic">
        <Fa icon="twitter"/>
      </a>
      <a className="p-2 fa-lg dribbble-ic">
        <Fa icon="dribbble"/>
      </a>
    </Col>,
  ])
}

export default Daniel


