import React from 'react';

import { 
  Col,
  Row,

} from 'mdbreact'

import { estiloInputNumerico } from '../estilos/estiloInputNumerico'

const CantidadLozas = (props) => {
  return (
    <section style={{marginLeft:'80px',}}>
      <br/>
      <h3>
        3. Indique la cantidad de lozas donde están ubicados los recipientes:
      </h3>
      <br/>
      <Row>
        <Col sm='1'>

          <input
            name='cantidadLozas'
            style={estiloInputNumerico}
            size="sm"
            onChange={(e) => {props.setCantidadLozas(e) }}
            value={props.getCantidadLozas()}
            label='Cantidad de lozas'
          />
        </Col>
        <Col sm='5'>
              <p>lozas</p>
            </Col>
      </Row>
    </section>
  )
}

export default CantidadLozas 