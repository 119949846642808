// Firebase config

// import firebase from 'firebase'
import firebase from 'firebase'
import 'firebase/firestore' 

// import { store } from '../store/iniciarApp';

const config  = {
    apiKey: "AIzaSyA6NbN-QcRwczmF_ogxmpN22TvVfmPbyB4",
    authDomain: "certificacion-glp.firebaseapp.com",
    databaseURL: "https://certificacion-glp.firebaseio.com",
    projectId: "certificacion-glp",
    storageBucket: "certificacion-glp.appspot.com",
    messagingSenderId: "757033177301"
  };
  // firebase.initializeApp(config);

// firebase.initializeApp(firebaseConfig);

const settings = {
  timestampsInSnapshots: true
}

let database = null
let authLogin = null
let storage = null 

const  inicializarFirebase = () => {
  firebase.initializeApp(config)
  database = firebase.firestore()
  authLogin = firebase.auth()
  database.settings(settings)
  storage = firebase.storage();
} // fin inicialiarFirebase
 



export { database, authLogin, inicializarFirebase, storage }