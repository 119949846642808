import React from 'react';
import { Col, Row} from 'mdbreact'


const TextoGasLicuado = () => {
  return (
    <section  className="static">
      <h1 style={{textAlign:'center', padding:'40px'}}>
        INTRODUCCION AL GAS LICUADO DE PETROLEO GLP
      </h1>
      <Row style={{marginLeft:'20px', marginRight:'20px', fontSize:'20px'}}>
        <Col>
          <p>
            El Gas Licuado de Petróleo, abreviado como GLP, es una de las fuentes de energía que tiene mayor potencial calorífico y que puede por sus características desempeñar cualquiera de las funciones de los combustibles primarios de los que se deriva.
          </p>

          <p>
            El GLP cuenta con amplias ventajas medioambientales y económicas en comparación con las energías tradicionales.
          </p>

        </Col>

        <Col>
          <p>
            El gas que entrega RECOPE y que se comercializa en el territorio nacional es 70% Gas Propano y 30% Gas Butano.
          </p>

          <p>
            Este gas tiene muchos usos en la industria, a nivel comercial y doméstico. Es una fuente valiosa de energía a bajo costo. Se utiliza ampliamente  en la industria plástica, como combustible de automóviles con la variante llamada autogas, en  refinerías y por supuesto en el consumo doméstico por medio de cilindros portátiles y de tamaño medio para sodas, comedores, restaurantes, hoteles, etc.
          </p>
        </Col>

        <Col>
          <p>
            Durante su combustión, el GLP genera un 36% menos de emisiones de CO2 (dióxido de carbono) que el carbón. Un 15% menos que la gasolina y un 10% menos que el diésel.
          </p>

          <p>
            Debido a su alta volatilidad, el Gas Licuado no supone un impacto dañino para el medio ambiente, además, a diferencia de otras fuentes energéticas, no genera residuos. 
          </p>
        </Col>
       </Row>
    </section>
  )
}
  

export default TextoGasLicuado 