const dataCilindros = [
  {tipo:'Cilindro 20 libras',     cantidad: '', masa:  9.09},
  {tipo:'Cilindro 25 libras',     cantidad: '', masa:  11.36},
  {tipo:'Cilindro 45 libras',     cantidad: '', masa:  20.45},
  {tipo:'Cilindro 100 libras',    cantidad: '', masa:  45.45},
  {tipo:'Tanque de 60 galones',  cantidad: '', masa: 114.46},
  {tipo:'Tanque de 80 galones',    cantidad: '', masa: 152.61},
  {tipo:'Tanque de 120 galones',   cantidad: '', masa: 228.92},
  {tipo:'Tanque de 250 galones',   cantidad: '', masa: 476.91},
  {tipo:'Tanque de 500 galones',   cantidad: '', masa: 953.82},
  {tipo:'Tanque de 1000 galones', cantidad: '', masa: 1907.64},
  {tipo:'Tanque de 1200 galones',  cantidad: '', masa: 2289.17},
]

export default dataCilindros