import React from 'react';

const Titulo = () => {
  return (
    <section>
      <h1 style={{marginTop:'150px', textAlign:'center'}}>
        Cotización de Certificaciones de Instalaciones de Gas GLP
      </h1>
      <br/>
    </section>
  )
}

export default Titulo