import React from 'react';

import { 
  Col,
  Row,
} from 'mdbreact'

import { estiloInputNumerico } from '../estilos/estiloInputNumerico'
const CantidadPisos = (props) => {
  return (
    <section style={{marginLeft:'80px',}}>
      <br/>
      <h3>
        6. Indique la cantidad de pisos que tiene la estructura (edificio):
      </h3>
      <br/>
      <Row>
        <Col sm='1'>
          <input
            name='cantidadPisos'
            style={estiloInputNumerico}
            size="sm"
            onChange={(e) => props.setPisos(e)}
            value={props.getPisos()}
          />
         </Col>
            <Col sm='5'>
            <p>pisos</p>
          </Col>
        </Row>
    </section>
  )
}

export default CantidadPisos