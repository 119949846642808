
import React from 'react';
import { 
  Col, 
  Row,
  Card,
  CardBody,
  Fa,
  Input,
  Button,
  Avatar

} from 'mdbreact'
const Andres = () => {
  return ([
    <Col md="3" lg="3" className="float-left">
      <img className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid" tag="img" src="/images/team/Andres.png" alt="Smiley face" />
    </Col>,

    <Col md="9" lg="9" className="float-right">
      <h4 className="font-weight-bold mb-3">Andrés Valenciano</h4>
      <h6 className="font-weight-bold grey-text mb-3">Licenciado en Ingeniería Química Universidad de Costa Rica</h6>
      <p>
        El Ing. Andrés Valenciano es Licenciado en Ingeniería Química y se encuentra debidamente inscrito en el Colegio de Ingenieros Químicos y Profesionales Afines (CIQPA).
      </p>
      <a className="p-2 fa-lg fb-ic">
        <Fa icon="facebook"/>
      </a>
      <a className="p-2 fa-lg tw-ic">
        <Fa icon="twitter"/>
      </a>

    </Col>
])}

export default Andres