import React from 'react';

import { 
  Row,
  Input,
} from 'mdbreact'

const TipoEstablecimiento = (props) => {

  const tiposInstalacion = [
    'Punto de venta de cilindros',
    'Distribuidor de cilindros',
    'Soda',
    'Restaurante',
    'Bar',
    'Sala de eventos',
    'Pensión',
    'Hotel pequeño (menos de 31 habitaciones y menos de 7 pisos)',
    'Hotel grande (más de 30 habitaciones o más de 7 pisos',
    'Centro comercial',
    'Industrial pequeño',
    'Industrial grande o comercial complejo u Hotel complejo',
    'Centro comercial pequeño',
    'Centro comercial complejo'
  ]
  return (
    <section style={{marginLeft:'80px',}}>
      <br/>
        {/* <Row> */}
          <h3>
            1. Seleccione el tipo de instalación:
          </h3>
        {/* </Row> */}
        <Row style={{display:'inline-flex'}}>
          {tiposInstalacion.map(tipo => {
            return (
              <Input
                style={{marginLeft:'35px'}}
                onClick={() => props.setTipoEstablecimiento(tipo)}
                checked={props.tipoEstablecimiento === tipo ? true : false}
                label={tipo}
                type="radio"
                id={tipo}
                key={tipo}/>
            )   
          })}  
          
        </Row>
    </section>
  )}

  export default TipoEstablecimiento