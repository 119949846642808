import React from 'react';
import { 
  Col, 
  Row,
  Card,
  CardText,
  CardTitle,
  CardImage,
  CardBody,
  CardGroup,
} from 'mdbreact'

const titulo = () => {
  return (
    <h1 id='textoServicios' style={{textAlign:'center', padding:'40px'}}>
      SERVICIOS DE GLP
    </h1>
  )
}

const parrafo = () => {
  return (
    <Row style={{marginLeft:'30px', marginRight:'30px', fontSize:'20px'}}>
      <p>
        TRAQUISA brinda una gran variedad de servicios en el área de ingeniería química tales como diseño de planos, análisis de riesgos, regencias y muchos más. Específicamente en el área de GLP y por requerimiento de la legislación nacional TRAQUISA ofrece los siguientes servicios.
      </p>
    </Row>
  )
}


const Certificaciones = () => {
  return(
    <Card style = {{margin:'30px'}}>
      <CardImage top  src="/images/glp/Picture57.png" overlay="white-slight" hover waves alt="Card image cap"/>
      <CardBody className=" rounded-bottom">
        <CardTitle>CERTIFICACION DE SISTEMAS DE GLP</CardTitle>
        <hr/>
        <CardText style={{fontSize:'18px'}} >Basado en la Legislación Nacional y Normativa aplicable se ofrece el servicio de Certificación de Sistemas de GLP y consiste de:</CardText>

        <CardText style={{fontSize:'18px'}} >Basado en la Legislación Nacional y Normativa aplicable se ofrece el servicio de Certificación de Sistemas de GLP y consiste de:
        <ul>
          <li>Visita de inspección a las instalaciones</li>
          <li>Evaluación del sistema de GLP en el área de almacenamiento y de abastecimiento</li>
          <li>Reporte de inspección</li>
          <li>Certificación de la instalación de GLP de acuerdo con el cumplimiento y recomendaciones dadas.</li>
        </ul></CardText>
      </CardBody>
    </Card>
  )
}

const Instalaciones = () => {
  return (
    <Card style={{margin:'30px'}}>
    <CardImage top  src="/images/glp/Picture16.png" overlay="white-slight" hover waves alt="Card image cap"/>
    <CardBody className=" rounded-bottom">
      <CardTitle>
        INSTALACIÓN DE TUBERÍAS, TANQUES, ACCESORIOS Y EQUIPOS INDUSTRIALES
      </CardTitle>
      <hr/>
      <CardText style={{fontSize:'18px'}} >
        <ul>
          <li>Basado en un diseño y análisis de requerimientos</li>
          <li>Presupuesto de tuberías, equipos y accesorios</li>
          <li>Programación de instalación</li>
          <li>Control de calidad en la instalación</li>
          <li>Pruebas requeridas para la garantía de la instalación</li>
          <li>Certificaciones de instalaciones, accesorios y materiales.</li> 

        </ul>
      </CardText>

    </CardBody>
  </Card>
  )
}

const Permisos = () => {
  return (
    <Card style={{margin:'30px'}}>
    <CardImage  top src="/images/glp/Picture59.png" overlay="white-slight" hover waves alt="Card image cap"/>
    <CardBody className=" rounded-bottom">
      <CardTitle>
        PERMISOS DE HIDROCARBUROS
      </CardTitle>
      <hr/>
      <hr/>
      <CardText style={{fontSize:'18px'}} >
        <ul>
          <li>Evaluación de sistemas de almacenamiento, transporte, distribución y proceso que involucran hidrocarburos líquidos y gaseosos.</li>
          <li>Análisis de la normativa y regulación vigente para cumplimiento de la instalación</li>
          <li>Elaboración de planos civil, mecánico, operaciones unitarias y eléctricos del proyecto</li>
          <li>Pruebas de comprobación de condiciones idóneas para el permiso correspondiente</li>
          <li>Tramitología en las diferentes entidades gubernamentales requeridas para la obtención del permiso y obtención de éste.</li>
        </ul>
      </CardText>

    </CardBody>
  </Card>
  )
}

const TextoServicios = () => {
  return (
    <div>

      {titulo()}
      {parrafo()}

      <br/>

      <Row>
        <CardGroup deck>
          {/* <Col>{Certificaciones()}</Col>
          <Col>{Instalaciones()}</Col>
          <Col>{Permisos()}</Col> */}

          {Certificaciones()}
          {Instalaciones()}
          {Permisos()}


        </CardGroup>
      </Row>
      
 


    </div>
  )
}

export default TextoServicios