import React from 'react';

const textoCorreoMensaje = (data) =>
{
  let html = ''

  const linea = (texto) => {
    html = html + texto
  }

  linea('<div>')
  linea(`<h3>Estimado señor(oa) ${data.nombre}</h3>`)
  linea("<p>Hemos recibido su mensaje creado en el website certificacion-glp.traquisa.net .</p>")
  
  linea('<br/><hr>')
  linea('<p>Su mensaje:</p>')
  linea(`<p>"${data.mensaje}"</p>`)
  linea('<hr><br/>')


  linea("<p>Pronto le estaremos contactando para contestar a sus preguntas y comentarios.</p>")

  linea("<p>Le agradecemos nos haya contactado .</p>")

  linea("<p>Atentamente </p>")

  linea("<p>Ing. Eugenia Aguero Castro.</p>")

  linea('</div>')

  return(html)
}

export default textoCorreoMensaje
