import React from 'react';

const Parrafo = () => {
  return (
    <section style={{marginLeft:'40px'}}>
      <h3>
        Utilice la siguiente calculadora para obtener un precio aproximado de una certificación de INSTALACION DE GAS GLP, confeccionada por nuestra oficina.
      </h3>
    </section>
  )
}

export default Parrafo