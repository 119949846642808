
import React from 'react';
import { 
  Col, 
  
  Fa,


} from 'mdbreact'

const Eugenia = () => {
  return (
    [
    <Col md="3" lg="3" className="float-left">
      <img className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid" tag="img" src="/images/team/Eugenia.png" alt="Smiley face" />
    </Col>,

    <Col md="9" lg="9" className="float-right">
      <h4 className="font-weight-bold mb-3">Eugenia Aguero Castro</h4>
      <h6 className="font-weight-bold grey-text mb-3">Licenciada en Ingeniería Química</h6>
      <p>
        La Ing. Eugenia Agüero es Licenciada en Ingeniería Química debidamente inscrita ante el Colegio de Ingenieros Químicos y Profesionales Afines (CIQPA). Cuenta con más de 27 años de experiencia en diferentes áreas de la Ingeniería Química, Seguridad, Riesgos y Ambiente.
      </p>
      <p>
        Nominada y seleccionada como Profesional Distinguida en el Año 2015 en el Colegio de Ingenieros Químicos y Profesionales Afines.
      </p>
      <p>
        Miembro activo del comité de GLP de Inteco, basado en la Norma NFPA 58 y NFPA 54 y participante del Comité de Tanques de combustibles de acuerdo con las normas UL142 y UL 1746.
      </p>
      <a className="p-2 fa-lg fb-ic">
        <Fa icon="facebook"/>
      </a>
      <a className="p-2 fa-lg tw-ic">
        <Fa icon="twitter"/>
      </a>
 
    </Col>
    ])
}

export default Eugenia