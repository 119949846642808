import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { database } from '../firebase/config' 
import textoCorreoMensaje from '../texto/textoCorreoMensaje'
import {
  Col, 
  Row,
  Card,
  CardBody,
  Fa,
  Input,
  Button,
} from 'mdbreact'
import Axios from 'axios';

class PaginaContactenos extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fechaMensaje: '',
      nombre: '',
      correo: '', 
      telefono: '',
      nombreEmpresa: '',
      mensaje: ''
    }
  }

  datosInvalidos = () => {
    if (!this.state.nombre) {
      toast.warning ('Digite su nombre')
      return true
    }

    if (!this.state.correo) {
      toast.warning ('Digite su dirección de correo')
      return true
    }

    if ( !( /\S+@\S+\.\S+/.test(this.state.correo) ) ) {
      toast.warning ('Digite una dirección de correo válida')
      return
    }

    if (!this.state.telefono) {
      toast.warning ('Digite su número de teléfono')
      return true
    }

    if ( !( /^\d{4}([-]\d{4})$/.test(this.state.telefono) ) ) {
      toast.warning ('Digite un número de teléfono válido')
      return
    }

    if (!this.state.nombreEmpresa) {
      toast.warning ('Digite el nombre de su empresa')
      return true
    }

    if (!this.state.mensaje) {
      toast.warning ('Digite su mensaje')
      return true
    }

  }

  enviarMensaje = async () => {
    if ( this.datosInvalidos() ) {
      console.log('datos invalidos')
      return
    }

    const mensajeRef = database.collection('mensajes').doc()
    try {
      await mensajeRef.set({
        nombre: this.state.nombre,
        telefono: this.state.telefono,
        correo: this.state.correo,
        nombreEmpresa: this.state.nombreEmpresa,
        mensaje: this.state.mensaje,
      })

      const html = textoCorreoMensaje(this.state)
      
      await Axios.post ('https://traquisa-mailer.herokuapp.com/api/enviarCorreo', {
        to: this.state.correo,
        from: 'soporte@it.scs.com',
        subject: 'Solicitud de información',
        html: html,
        passw: process.env.REACT_APP_PASSW,
      })

      toast.success('Su mensaje fue enviado a Traquisa, pronto le estaremos contactando. Muchas gracias')
    }  // fin de try
    catch(error) {console.log('error en grabacion de mensaje:', error) }
  }

  render () {
    return (
      <div>
        <section className="section parallax bg56">
        </section>
        <h1 style={{textAlign:'center', padding:'40px', color:'yellow' }}>
          Contáctenos por correo o por teléfono
        </h1>
  
        <Card   style={{ width:'80%', left:'10%', marginBotton:'10px',  top: '-150px'}}> 
          <Row id='textoContacto' >
            <Col sm="8">
              <CardBody className="form">
                <h3 className="mt-4">
                  <Fa icon="envelope" className="pr-2"/>Escríbanos:
                </h3>
                <Row>
                  <Col md="6">
                    <div className="md-form mb-0">
                      <Input 
                        type="text" 
                        id="form-contact-name" 
                        label="Su nombre" 
                        onChange={e => this.setState({nombre: e.target.value})}
                        value = {this.state.nombre}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="md-form mb-0">
                      <Input 
                        type="text" 
                        id="form-contact-email" 
                        label="Su correo"
                        onChange={e => this.setState({correo: e.target.value})}
                        value= {this.state.correo}
                      />
                    </div>
                  </Col>
                </Row>
  
                <Row>
                  <Col md="6">
                    <div className="md-form mb-0">
                      <Input 
                        type="text" 
                        id="form-contact-phone" 
                        label="Su teléfono"
                        hint='2122-2324'
                        onChange={e => this.setState({telefono: e.target.value})}
                        value={this.state.telefono}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="md-form mb-0">
                      <Input 
                        type="text" 
                        id="form-contact-company" 
                        label="Su empresa"
                        onChange={e => this.setState({nombreEmpresa: e.target.value})}
                        value={this.state.nombreEmpresa}
                      />
                    </div>
                  </Col>
                </Row>
  
                <Row>
                  <Col md="12">
                    <div className="md-form mb-0">
                      <Input 
                        type="textarea" 
                        id="form-contact-message" 
                        label="Su mensaje"
                        onChange={e => this.setState({mensaje: e.target.value})}
                        value={this.state.mensaje}                        
                      />
                      <br/> <br/>
                      <Button tag="a" floating color="blue" size="lg" onClick={ this.enviarMensaje}>
                        <Fa icon="send-o"/>
                      </Button>
                    </div>
                  </Col>
                </Row>
  
              </CardBody>
            </Col>
            <Col sm="4" style={{backgroundColor:'#103f6d'}}>
              <CardBody className="contact text-center h-100 white-text" >
                <h3 className="my-4 pb-2">Nuestros datos de contacto</h3>
                <ul className="text-lg-left list-unstyled ml-4">
                  <li>
                    <p><Fa icon="map-marker" className="pr-2"/>Llorente de Tibás, 100 Este, 20 sur de la heladería Pops.  San José.</p>
                  </li>
                  <li>
                    <p><Fa icon="phone" className="pr-2"/>(506)2241-4538</p>
                  </li>
                  <li>
                    <p><Fa icon="fax" className="pr-2"/>(506)2297-2748</p>
                  </li>
                  <li>
                    <p><Fa icon="envelope" className="pr-2"/>servicioalcliente@traquisa.net</p>
                  </li>
                  <li>
                    <p><Fa icon="envelope" className="pr-2"/>eaguero@traquisa.net</p>
                  </li>                
                </ul>
                <hr className="hr-light my-4"/>
                <ul className="list-inline text-center list-unstyled">
  
                  <li className="list-inline-item">
                    <a href='#' className="p-2 fa-lg w-ic">
                      <Fa icon="facebook"/>
                    </a>
                  </li>
  
                  <li className="list-inline-item">
                    <a href='#' className="p-2 fa-lg w-ic">
                      <Fa icon="twitter"/>
                    </a>
                  </li>
  
                  <li className="list-inline-item">
                    <a href='#'  className="p-2 fa-lg w-ic">
                      <Fa icon="linkedin"/>
                    </a>
                  </li>
                  
                  <li className="list-inline-item">
                    <a href='#' className="p-2 fa-lg w-ic">
                      <Fa icon="instagram"/>
                    </a>
                  </li>
  
                </ul>
              </CardBody>
            </Col>
          </Row>
        </Card>

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
          lightBackground
          // position={ToastContainer.POSITION.BOTTOM_CENTER}
          position='top-center'
          />

      </div>
    )

  }
}

export default PaginaContactenos