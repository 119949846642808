import React from 'react';

const textoCorreoCotizacion = (data) =>
{
  let html = ''

  const linea = (texto) => {
    html = html + texto
  }

  linea('<div>')
  linea(`<h3>Estimado señor(oa) ${data.nombre}</h3>`)
  linea("<p>Hemos recibido su solicitud de cotización de servicios de Certificación de uso de gas licuado del petroleo (GLP).</p>")
  
  linea('<p>La cotización la estaremos calculando con base en lo siguientes datos:</p>')

  linea('<ul>')
  linea(`<li>Tipo de instalación: ${data.tipoEstablecimiento}</li>`)
  linea('<li>Cantidad de cilindros')
  linea('<ul>')
    data.cilindros.forEach(cil => {
      if (cil.cantidad) {
        linea(`<li>${cil.tipo}: ${cil.cantidad}</li>`)
      }
    });

  linea('</ul></li>')


  linea(`<li>Cantidad de lozas: ${data.cantidadLozas}</li>`)

  linea(`<li>Cantidad de equipos: ${data.cantidadEquipos}</li>`)

  linea(`<li>Cantidad de Pisos: ${data.cantidadPisos}</li>`)

  linea(`<li>Area: ${data.area}</li>`)

  linea(`<li>Precio aproximado:${data.precio}USD</li>`)

  linea('</ul>')

  linea("<p>Pronto le estaremos contactando para entregarle nuestra cotización.</p>")

  linea("<p>Le agradecemos mucho habernos tomado en cuenta para colaborar con su empresa.</p>")


  linea("<p>Atentamente </p>")

  linea("<p>Ing. Eugenia Aguero Castro.</p>")
  linea("<p>Transformaciones Químicas S.A.</p>")
  // linea(`<img src= "images/LogoTraquisa-200x141.png" height="75" alt=''/>`)

  linea('</div>')

  return(html)
}

export default textoCorreoCotizacion
