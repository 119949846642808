
import React from 'react';
import { 
  Col, 
  Row,
 
  Fa,
  

} from 'mdbreact'

const Tamy = () => {
  return ([
    <Col md="3" lg="3" className="float-left">
      <img className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid" tag="img" src="/images/team/Tamy.png" alt="Smiley face" />
    </Col>,

    <Col md="9" lg="9" className="float-right">
      <h4 className="font-weight-bold mb-3">Tamy Bonilla Soto</h4>
      <h6 className="font-weight-bold grey-text mb-3">Estudiante Administración de Empresas</h6>
      <p>
        Estudiante avanzada de Administración de Empresas
      </p>
      
      <a className="p-2 fa-lg fb-ic">
        <Fa icon="facebook"/>
      </a>
      <a className="p-2 fa-lg tw-ic">
        <Fa icon="twitter"/>
      </a>
 
    </Col>
])}

export default Tamy