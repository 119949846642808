import React from 'react';
import { Col, Row} from 'mdbreact'


const TextoPerfil = () => {
  return (
    <div>
      <Row>
        <Col>
          <h1 style={{textAlign:'center', padding:'40px'}}>
            Perfil de Transformaciones Químicas S.A. (TRAQUISA)
          </h1>
        </Col>
      </Row>

      <Row id='textoPerfil' style={{marginLeft:'20px', marginRight:'20px', fontSize:'20px'}}>
        <Col>
          <p>
            Transformaciones Químicas, S.A. es una empresa costarricense de servicios diversificados en el área de la Ingeniería Química, Ambiente y Seguridad entre otros que se enfoca en desarrollar las diferentes actividades de una forma eficiente en cumplimiento con la legislación nacional y normativas aplicables.
          </p>
        </Col>

        <Col>
          <p>
            La empresa ha desarrollado un esquema de trabajo con el paso de los años que garantizan los resultados esperados para cada cliente que se le proporciona un servicio, siempre en cumplimiento de lo que requiere la legislación nacional con innovación y diseño para este cumplimiento.
          </p>
        </Col>
  
        <Col>
          <p>
            Nuestro posicionamiento en el sector de servicios de Regencia Química y consultoría es de alto nivel y reconocimiento. La unión de diversos talentos del equipo de trabajo y la relación interdisciplinaria del mismo se ha mantenido por más de 25 años siendo una empresa única en la integración de ofertas de servicios de calidad en las áreas de la Ingeniería Química, ambiente y seguridad.
          </p>
        </Col>
      </Row>

      <br/> <hr style={{width:'90%'}}/> 

      <Row style={{marginLeft:'20px', marginRight:'20px', fontSize:'20px'}}>
        <Col>
          <h1 style={{textAlign:'center', }}>
            Nuestra Visión
          </h1>
          <p>
            Ser la empresa líder a nivel nacional en servicios de Regencia Química y consultoría en el área de la Ingeniería Química y Profesionales Afines nuestra diversidad de servicios, amplio conocimiento en las diferentes áreas que nos desempeñamos, nuestra eficiencia, calidad de servicio y ética.  Ser reconocida por la calidad humana y profesional de nuestro personal y nuestra contribución a la sociedad costarricense.

          </p>
        </Col>

        <Col>
          <h1 style={{textAlign:'center', }}>
            Nuestra Misión
          </h1>        
          <p>
            Colaborar de una forma eficiente y ética a nuestros clientes que requieren de nuestros servicios para lograr un resultado a sus requerimientos oportunamente y con total cumplimiento normativo y regulatorio.
          </p>
        </Col>
      </Row>
      <br/>


    </div>
  )
}

export default TextoPerfil 

// const estilo1 = {
//   position: 'absolute',
//   top: '10px',
//   left: '250px',
//   maxWidth: '600px'
// }

// const anchoFoto = {
//   width: '200px'
// }