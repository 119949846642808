import React from 'react';

import { 
  Col,
  Row,
} from 'mdbreact'

import { estiloInputNumerico } from '../estilos/estiloInputNumerico'

const Area = (props) => {
  return (
    <section style={{marginLeft:'80px',}}>
      <br/>
      <h3>
        5. Indique el área del establecimiento en metros cuadrados:
      </h3>
      <br/>
      <Row>
        <Col sm='1'>
          <input
            style={estiloInputNumerico}
            size="sm"
            onChange={(e) => props.setArea(e.target.value)}
            value={props.getArea()}
            label='Area en m2'
          />
         </Col>
            <Col sm='5'>
            <p>metros cuadrados</p>
          </Col>
        </Row>
    </section>
  )
}


export default Area