import React from 'react';

import { 
  Col,
  Row,
} from 'mdbreact'

import { estiloInputNumerico } from '../estilos/estiloInputNumerico'

const CantidadCilindros = (props) => {
  return (
    <section style={{marginLeft:'80px',}}>
      <br/>
      <h3>
        2. Indique tipo y cantidad de cilindros con los que cuenta su empresa
      </h3>
      <p style={{marginLeft:'30px',}}><i>(Si usted tiene un tipo de cilindro que no se muestra en la tabla a continuación, por favor contáctenos por teléfono o por medio de la opción de menú "Contáctenos" de ésta página)</i></p>
      <br/>

      <Row>
        <Col>
          {props.cilindros.map( (cilindro, index)  => {
            return ( index < 4 &&
              <Row >
                <Col sm='2'>
                  <input
                    style={estiloInputNumerico}
                    size="sm"
                    onChange={(e) => props.setCantidadCilindro(cilindro.tipo, e.target.value)}
                    value={props.getCantidadCilindro(cilindro.tipo)}
                    label={cilindro.tipo}
                    key={cilindro.tipo}
                    id={cilindro.tipo}/>
                </Col>
                <Col sm='5'>
                  <p style={{marginLeft: '10px'}}>{cilindro.tipo}</p>
                </Col>
              </Row>
            )
          })}
        </Col>
        <Col>
          {props.cilindros.map( (cilindro, index)  => {
              return ( index > 3 && index < 8 &&
                <Row >
                  <Col sm='2'>
                    <input
                      style={estiloInputNumerico}
                      size="sm"
                      onChange={(e) => props.setCantidadCilindro(cilindro.tipo, e.target.value)}
                      value={props.getCantidadCilindro(cilindro.tipo)}
                      label={cilindro.tipo}
                      key={cilindro.tipo}
                      id={cilindro.tipo}/>
                  </Col>
                  <Col sm='5'>
                    <p style={{marginLeft: '10px'}}>{cilindro.tipo}</p>
                  </Col>
                </Row>
              )
            })}
        </Col>
        <Col>
          {props.cilindros.map( (cilindro, index)  => {
              return ( index > 7 &&
                <Row >
                  <Col sm='2'>
                    <input
                      style={estiloInputNumerico}
                      size="sm"
                      onChange={(e) => props.setCantidadCilindro(cilindro.tipo, e.target.value)}
                      value={props.getCantidadCilindro(cilindro.tipo)}
                      label={cilindro.tipo}
                      key={cilindro.tipo}
                      id={cilindro.tipo}/>
                  </Col>
                  <Col sm='5'>
                    <p style={{marginLeft: '10px'}}>{cilindro.tipo}</p>
                  </Col>
                </Row>
              )
            })}
        </Col>
      </Row>

    </section>
  )
}

export default CantidadCilindros