import React from 'react';
import { Col, Row} from 'mdbreact'

const TextoConsumoNacional= () => {
  return (
    <div>
      <h1 id='mercadoNacional'  style={{textAlign:'center', padding:'40px'}}>
        CONSUMO NACIONAL DE GLP
      </h1>
      <Row style={{marginLeft:'20px', marginRight:'20px', fontSize:'20px', paddingBottom:'40px'}}>
        <Col>
          <p style={{marginTop:'175px', fontSize:'20px', width:'600px', left:'500px'}} >
            En el 2017 se registró una venta de 302 millones de litros de GLP por parte de RECOPE, y presentó un incremento con respecto al año 2016 de un 6%, por lo que se espera que el GLP siga creciendo al menos a un ritmo del 5% anual. Según la 
            <a href='https://aresep.go.cr/63-calidad/2326-informe-de-calidad-de-glp-i-semestre-2018?showall=&start=2'> ARESEP.</a>
          </p>

        </Col>
        <Col>
          <img src="/images/glp/Resumen2017.png" className="img-fluid" alt="Smiley face" />
        </Col>
      </Row>
    </div>
  )
}

export default TextoConsumoNacional 