import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './estilos/Estilos.css';

// Sin estas 3 lineas no formatea bien mdbreact
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import {  Route, BrowserRouter } from 'react-router-dom'

import Layout from './layout/Layout';

import PaginaCotizaciones from './paginas/PaginaCotizaciones'
import PaginaContactenos from './paginas/PaginaContactenos'
import PaginaInformacion from './paginas/PaginaInformacion'

// const estiloTitulo = {
//   position: 'relative',
//   left: '100px',
//   top: '30px',
//   color:'yellow'
// };

import { inicializarFirebase, authLogin } from './firebase/config'

import { render } from 'react-snapshot'

class App  extends Component {
  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Route exact path ='/' component={PaginaInformacion} />
          <Route exact path = '/cotizaciones' component = {PaginaCotizaciones} />
          <Route exact path = '/contactenos' component = {PaginaContactenos} />
        </Layout>
      </BrowserRouter>
    )  
  }
} //fin de App

inicializarFirebase()
authLogin.signInWithEmailAndPassword('soporte@it-scs.com', 'B4#NibW6op')


// ReactDOM.render(<App/>, document.getElementById('root'))

//prueba de react-snapshot
render(<App/>, document.getElementById('root'))


