

// React
import React from 'react';
import '../estilos/Estilos.css' 


// Interface del usuario (GUI)
import { Col , Row, Footer } from 'mdbreact';

const PiePagina = (props) => {


  return(
    <Footer className= 'estilo-footer' color={ color }>
      <Row>
        <Col >
          <h5 className="estilo-texto-fotter">Certificaciones de uso de gas GLP</h5>
        </Col>

        <Col style={textoCentro}>
            <h5 className="estilo-texto-fotter">
              Transformaciones Químicas S.A.
            </h5>
        </Col>

        <Col style={textoDerecha}>
            <h5 className="estilo-texto-fotter">
              
            </h5>
        </Col>

      </Row>
      <Row>
        <Col style={{textAlign: 'center'}}>
          <p>
            &copy; {(new Date().getFullYear())} Todos los derechos reservados/>
          </p>
        </Col>
      </Row>


  <div className="footer-copyright text-center">
  </div> 

    </Footer>
    );
}
      
export default PiePagina

 const textoDerecha = {
   textAlign: 'right',
 }

 const textoCentro = {
    textAlign: 'center',
 }

const color = 'elegant-color-dark'
// const color = 'light-green darken-3'

// const estiloFooter =  {
//   marginBottom:'0px'
// }