
import React from 'react';
import { 
  Col, 
   
  Fa,
   

} from 'mdbreact'
const Jose = () => {
  return ([
    <Col md="3" lg="3" className="float-left">
    <img className="mx-auto mb-md-0 mb-4 rounded z-depth-1 img-fluid" tag="img" src="/images/team/Jose.png" alt="Smiley face" />
  </Col>,

  <Col md="9" lg="9" className="float-right">
    <h4 className="font-weight-bold mb-3">Jose Amador Aguero</h4>
    <h6 className="font-weight-bold grey-text mb-3">Licenciado en Ingeniería Química</h6>
    <p>
      El Ing. José Luis Amador Agüero es Licenciado en Ingeniería Química debidamente inscrito ante el Colegio de Ingenieros Químicos y Profesionales Afines (CIQPA).
    </p>
    <p>
      Cuenta con amplia experiencia en diversos trámites ante instituciones gubernamentales en temas regulatorios para la industria en general.
    </p>
    <p>
      Se encuentra debidamente certificado como inspector de instalaciones de GLP para industria y comercio.
    </p>
    <p>
      Actualmente se desempeña como consultor de Traquisa en el área de Ingeniería Química, inspector de instalaciones GLP y Regente Químico principalmente.
    </p>
    <a className="p-2 fa-lg fb-ic">
      <Fa icon="facebook"/>
    </a>
    <a className="p-2 fa-lg tw-ic">
      <Fa icon="twitter"/>
    </a>
  </Col>
  ])
}

export default Jose